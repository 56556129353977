import { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./App.scss";
import * as Api from "./services/Api";
import Message from "./components/layout/message/Message";
import DashBoard from "./components/dashboard/Dashboard.component";
import QuizBuilder from "./components/quiz-builder/QuizBuilder";
import MainScreen from "./components/master/main-screen/MainScreen";
import JoinGame from "./components/player-screen/join-game/JoinGame";
import { useAppDispatch, useAppSelector } from "./services/store/hooks";
import {
	getConfig,
	saveQueryString,
	saveToken,
} from "./services/store/features/configSlice";
import { CircularProgress } from "@mui/material";
import PlayerScreen from "./components/player-screen/player/PlayerScreen";
import MasterScreen from "./components/master/master-screen/MasterScreen";
import SliderComponent from "./components/partials/SliderComponent";
import { isMobile } from "react-device-detect";

function App() {
	let location = useLocation();
	let query = new URLSearchParams(location.search);
	const { i18n } = useTranslation();
	const lang = (query.get("lang") || "en") as "en" | "nl";
	const token = query.get("token");
	const magazineId = query.get("magazine");
	const [error, setError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	const dispatch = useAppDispatch();
	const { loading } = useAppSelector((state) => state.config);

	async function updateStats(signal: AbortSignal) {
		await Api.updateStats(
			token ?? "",
			magazineId ?? "",
			{
				magazineId: parseInt(magazineId!),
				date: new Date().toISOString(),
				visits: location.pathname === "/" ? 1 : 0,
				dashboardVisits: location.pathname === "/dashboard" ? 1 : 0,
			},
			signal
		);
	}

	useEffect(() => {
		const controller = new AbortController();
		async function loadMagazine() {
			try {
				dispatch(saveToken(token || ""));
				await dispatch(
					getConfig({
						token: token ?? "",
						magazineId: magazineId ?? "",
						signal: controller.signal,
					})
				);
				dispatch(saveQueryString(`?token=${token}&magazine=${magazineId}`));
				setError(false);
				setErrorMessage("");
			} catch (error) {
				setError(true);
				setErrorMessage("get Magazine failed, error: no token");
			}
		}
		loadMagazine();
		if (isMobile) {
			// TODO: should we add a listener for window resize event?
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		}
		return () => {
			controller.abort();
		};
	}, []);

	useEffect(() => {
		const controller = new AbortController();
		try {
			setError(false);
			setErrorMessage("");
			updateStats(controller.signal);
			i18n.changeLanguage(lang);
		} catch (error) {
			setError(true);
			setErrorMessage(`update statistics failed, error: ${error}`);
		}
		return () => {
			controller.abort();
		};
	}, [location]);

	if (loading) {
		return (
			<div className="h-100 w-100 d-flex align-items-center justify-content-center">
				<CircularProgress />
			</div>
		);
	}

	if (!error) {
		return (
			<Switch>
				<Route exact path="/">
					<SliderComponent isIn={location.pathname == "/"}>
						<JoinGame />
					</SliderComponent>
				</Route>
				<Route path="/game/:code">
					<SliderComponent isIn={location.pathname.includes("/game/")}>
						<MainScreen />
					</SliderComponent>
				</Route>
				<Route path="/player/screen/:code">
					<PlayerScreen />
				</Route>
				<Route path="/master/screen/:code">
					<SliderComponent isIn={location.pathname.includes("/master/screen/")}>
						<MasterScreen />
					</SliderComponent>
				</Route>
				<Route path="/dashboard">
					<SliderComponent isIn={location.pathname.includes("/dashboard")}>
						<DashBoard />
					</SliderComponent>
				</Route>
				<Route path="/edit-quiz/:_id">
					<SliderComponent isIn={location.pathname.includes("/edit-quiz/")}>
						<QuizBuilder />
					</SliderComponent>
				</Route>
				<Route path="/create-quiz">
					<SliderComponent isIn={location.pathname.includes("/create-quiz")}>
						<QuizBuilder />
					</SliderComponent>
				</Route>
			</Switch>
		);
	} else {
		return (
			<>
				<Message type="error" text={errorMessage} />
			</>
		);
	}
}

export default App;
