import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { Quiz } from "../../interfaces/quiz.interface";
import * as Api from "../../services/Api";
import Message from "../layout/message/Message";
import DashboardHeader from "./DashboardHeader";
import QuizCard from "./QuizCard";
import { TransitionGroup } from "react-transition-group";
import { Collapse } from "@mui/material";
import { useAppSelector } from "../../services/store/hooks";

function DashBoard() {
	const [quizes, setQuizes] = useState<Quiz[]>([]);
	const [error, setError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	const { magazine, token } = useAppSelector((state) => state.config);
	const { magazineId } = magazine;

	async function getQuizes() {
		if (token && magazineId) {
			try {
				const quizesData = await Api.getQuizes(token, magazineId);
				setQuizes(quizesData);
				setError(false);
				setErrorMessage("");
			} catch (err) {
				setError(true);
				if (err instanceof AxiosError && err.message.includes("403")) {
					setErrorMessage("you're not a quiz master");
				} else {
					setErrorMessage("something went wrong");
				}
			}
		} else {
			setError(true);
			setErrorMessage("invalid token");
		}
	}

	// handlers
	const handleQuizDelete = async (
		event: React.MouseEvent<HTMLButtonElement>,
		_id?: string
	) => {
		event.preventDefault();
		try {
			if (!_id) {
				setError(true);
				setErrorMessage("quiz not found");
			}
			// from backend
			await Api.deleteQuizById(token ?? "", magazineId ?? "", _id);
			// from frontend
			const data = [...quizes].filter((quiz) => quiz._id?.toString() !== _id);
			setQuizes(data);
			setError(false);
			setErrorMessage("");
		} catch (error) {
			setError(true);
			setErrorMessage("delete quiz failed");
		}
	};

	useEffect(() => {
		getQuizes();
	}, [token, magazineId]);

	if (!error) {
		return (
			<div className="container-fluid dashboard p-5">
				<DashboardHeader />
				{(quizes?.length && (
					<TransitionGroup>
						{quizes.map((quiz) => (
							<Collapse key={quiz._id}>
								<QuizCard
									quizName={quiz.quizName}
									_id={quiz._id}
									questionsCount={quiz.questionsCount}
									handleQuizDelete={handleQuizDelete}
								/>
							</Collapse>
						))}
					</TransitionGroup>
				)) || <h4 className="text-info">you have no quizes yet</h4>}
			</div>
		);
	} else {
		return (
			<>
				<Message type="error" text={errorMessage} />
			</>
		);
	}
}

export default DashBoard;
